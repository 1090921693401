import * as React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { getImage, getSrc } from "gatsby-plugin-image"
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import LayoutNew from "../components/layout-new"
import HeroSection from "../components/common/hero-section"
import Seo from "../components/seo"
import PortalPodcast from '../components/common/portal-podcast'

const PagePodcasts = () => {
  const data = useStaticQuery(graphql`
    query PagePodcastsQueryNew {
      prismicPodcastsPage {
        _previewable
        data {
          podcasts_page_title
          podcasts_page_short_description
          podcasts_section {
            title
            about
            audio_url
            share_link {
              link_type
              uid
              url
            }
            podcast_image {
              alt
              gatsbyImageData(
                width: 150
              )
            }
            status
          }
        }
      }
      imgHero: file(relativePath: { eq: "backgrounds/podcasts-bg.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

  const doc = data.prismicPodcastsPage
  const imgHero = getImage(data.imgHero)
  const imgSrc = getSrc(data.imgHero)

  // Filter 'New' podcasts to show at the top
  const podcastsWithTrueStatus = doc.data.podcasts_section.filter(podcast => podcast.status === true);
  const podcastsWithFalseStatus = doc.data.podcasts_section.filter(podcast => podcast.status === false);

  podcastsWithTrueStatus.sort().reverse();

  // Concatenate the arrays with true status item on top
  const podcasts = [...podcastsWithTrueStatus, ...podcastsWithFalseStatus];

  const [currentPage, setCurrentPage] = React.useState(1)
  const itemsPerPage = 10

  const totalPages = Math.ceil(podcasts.length / itemsPerPage)
  const paginatedPodcasts = podcasts.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  )

  const changePage = (page) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <LayoutNew>
      <Seo
        title={doc.data.podcasts_page_title}
        description={doc.data.testimonials_page_short_description}
        image={imgSrc}
        noIndex
      />
      <HeroSection
        title={doc.data.podcasts_page_title}
        imgSrc={imgHero}
      />

      <div className="bg-site-green-light pb-16">
        <div className="relative w-11/12 lg:w-10/12 max-w-screen-lg mx-auto p-2 lg:p-8">
          {paginatedPodcasts.map(item => {
            const podcastImg = getImage(item.podcast_image)

            return (
              <PortalPodcast
                key={item.title}  // Use a unique key for each podcast item
                podcastImg={podcastImg}
                podcastTitle={item.title}
                podcastText={item.about}
                audioUrl={item.audio_url}
                buttonLinkType={item.share_link.link_type}
                buttonLinkUid={item.share_link.uid}
                buttonLinkUrl={item.share_link.url}
              />
            )
          })}
        </div>
        {totalPages > 1 && (
          <div className="flex flex-wrap justify-center mt-4">
            {Array.from({ length: totalPages }).map((_, index) => (
              <button
                key={index + 1}
                className={`mx-2 px-3 py-1 border rounded hover:bg-site-red hover:text-white mb-4 ${
                  currentPage === index + 1 ? "bg-site-red text-white" : ""
                }`}
                onClick={() => changePage(index + 1)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        )}
      </div>
    </LayoutNew>
  )
}

export default withPrismicPreview(PagePodcasts)