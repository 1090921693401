import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import styled from 'styled-components'

const StyledDiv = styled.div`
  width: 150px;
  height: 150px;
`

const StyledDiv2 = styled.div`
  .rhap_container {
    box-shadow: unset;
  }
  .rhap_main-controls-button {
    color: #bb0000;
  }
  .rhap_button-clear {
    background-color: transparent;
  }
`

const PortalPodcast = ({ podcastImg, podcastTitle, podcastText, audioUrl, buttonLinkType, buttonLinkUrl, buttonLinkUid }) => {
  const data = useStaticQuery(graphql`
    query PortalPodcastQuery {
      cardImgPlaceholder: file(relativePath: { eq: "cards/podcasts-placeholder.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 150
          )
        }
      }
    }
  `)

  const cardImgPlaceholder = getImage(data.cardImgPlaceholder)

  return (
    <div className='w-full bg-white rounded-md shadow-md mt-16'>
      <div className='p-4 xl:p-8'>
        <div className='flex flex-col md:flex-row items-start'>
          <StyledDiv className='w-full xl:w-2/12 flex-shrink-0 mb-6 lg:mb-0 md:mr-6'>
            <GatsbyImage image={podcastImg ? podcastImg : cardImgPlaceholder} alt="Podcast Image" className='border rounded-md' />

            {buttonLinkType === "Document" || buttonLinkType === "Any"
              ?
                <Link
                  to={buttonLinkUid ? `/${buttonLinkUid}` : "/"}
                  className='hidden md:block'
                >
                  <button className="w-full flex items-center justify-center px-4 py-2 md:px-5 md:py-3 mt-6 md:mt-0 border border-transparent font-bold rounded-md text-sm text-white uppercase tracking-widest bg-site-red hover:bg-site-red-hover">
                    Share
                  </button>
                </Link>
              :
              buttonLinkType === "Web" || buttonLinkType === "Media"
              ?
                <a
                  href={buttonLinkUrl ? buttonLinkUrl : 'https://www.831b.com'}
                  target="_blank"
                  rel="noopener noreferrer"
                  className='hidden md:block'
                >
                  <button className="w-full flex items-center justify-center px-4 py-2 md:px-5 md:py-3 mt-6 md:mt-0 border border-transparent font-bold rounded-md text-sm text-white uppercase tracking-widest bg-site-red hover:bg-site-red-hover">
                    Share
                  </button>
                </a>
              :
              null
            }
          </StyledDiv>

          <div className='w-full xl:w-10/12'>
            <h2 className='text-2xl text-site-red font-bold mb-6'>{podcastTitle ? podcastTitle : 'Podcast Title'}</h2>
            <p className='text-gray-800 mb-6'><span className='font-bold uppercase'>About:</span> {podcastText ? podcastText : 'Podcast description here.'}</p>

            {audioUrl
              ?
              <StyledDiv2>
                <AudioPlayer src={audioUrl} />
              </StyledDiv2>
              :
              null
            }

            {buttonLinkType === "Document" || buttonLinkType === "Any"
              ?
                <Link
                  to={buttonLinkUid ? `/${buttonLinkUid}` : "/"}
                  className='md:hidden'
                >
                  <button className="w-full flex items-center justify-center px-4 py-2 md:px-5 md:py-3 mt-6 md:mt-0 border border-transparent font-bold rounded-md text-sm text-white uppercase tracking-widest bg-site-red hover:bg-site-red-hover">
                    Share
                  </button>
                </Link>
              :
              buttonLinkType === "Web" || buttonLinkType === "Media"
              ?
                <a href={buttonLinkUrl ? buttonLinkUrl : 'https://www.831b.com'} className='md:hidden'>
                  <button className="w-full flex items-center justify-center px-4 py-2 md:px-5 md:py-3 mt-6 lg:mt-0 border border-transparent font-bold rounded-md text-sm text-white uppercase tracking-widest bg-site-red hover:bg-site-red-hover">
                    Share
                  </button>
                </a>
              :
              null
            }
          </div>
        </div>
      </div>
    </div>
  )
}

PortalPodcast.propTypes = {
  podcastImg: PropTypes.string,
  podcastTitle: PropTypes.string,
  podcastText: PropTypes.string,
  audioUrl: PropTypes.string,
  buttonLinkType: PropTypes.string,
  buttonLinkUrl: PropTypes.string,
  buttonLinkUid: PropTypes.string,
}

export default PortalPodcast